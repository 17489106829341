.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body, html {
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.navLink {
  color: grey;
  text-decoration: none;
  height: 100%;
  padding: 0.5rem;
}

.navLink:hover{
  color: #404040;
  text-decoration: none;
}

.col{
  width: 50%;
}

.two-col{
  display: flex;
}

@media only screen and (max-width: 768px) {
  .col{
    width: 95%!important
  }

  .two-col{
    display: block
  }

  .content-container{
    width: 95%!important;
    margin-left: 2.5%!important
  }

  .name-card{
    width: 80%!important;
    margin-left: 10%!important
  }
}